'use client';

import {
  isServer,
  QueryClient,
  QueryClientProvider as ClientProvider,
} from '@tanstack/react-query';
import type { ReactNode } from 'react';

// Use a longer staleTime (5 minutes) on the client to reduce network traffic. The user can
// always refresh their browser to clear this cache.
//
// On the server, use a shorter staleTime (5 seconds) because network requests are faster and
// we always want the latest data.
function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: isServer ? 1000 * 5 : 1000 * 60 * 5,
      },
    },
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

const QueryClientProvider = ({
  children,
}: {
  readonly children: ReactNode;
}) => {
  const queryClient = getQueryClient();
  return <ClientProvider client={queryClient}>{children}</ClientProvider>;
};

export default QueryClientProvider;
