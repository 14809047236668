'use client';

import type { IIconProps } from '@/app/components/ui/Icon';
import Icon from '@/app/components/ui/Icon';
import type { ToastVariants } from '@/app/components/ui/Toast/toast';
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from '@/app/components/ui/Toast/toast';
import { useToast } from '@/app/hooks/use-toast';

const getVariantIconProps = (
  variant: ToastVariants['variant'],
): Pick<IIconProps, 'color' | 'icon'> => {
  switch (variant) {
    case 'success':
      return { icon: 'TICK_CIRCLE', color: 'green-success-50-shade' };
    case 'error':
      return { icon: 'ALERT_04', color: 'living-coral-700' };
    case 'warning':
      return { icon: 'ALERT_05', color: 'burnt-orange-700' };
    default:
      return { icon: 'INFO' };
  }
};

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(({ id, title, description, action, ...props }) => (
        <Toast key={id} {...props}>
          <div className="flex items-start gap-2">
            <Icon
              {...getVariantIconProps(props.variant)}
              height={20}
              width={20}
            />
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
          </div>
          {Boolean(action) && (
            <div className="ml-7 flex items-center gap-4">{action}</div>
          )}
          <ToastClose />
        </Toast>
      ))}
      <ToastViewport />
    </ToastProvider>
  );
}
