import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/AkkuratLL-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../fonts/AkkuratLL-Light.woff2\",\"weight\":\"300\",\"style\":\"light\"}],\"variable\":\"--font-sans\"}],\"variableName\":\"AkkuratLL\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../fonts/PPEditorialNew-Ultralight.woff2\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../fonts/PPEditorialNew-UltralightItalic.woff2\",\"weight\":\"200\",\"style\":\"italic\"}],\"variable\":\"--font-serif\"}],\"variableName\":\"EditorialNew\"}");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationBlockerProvider"] */ "/app/src/app/components/NavigationBlock/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/SessionWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/app/components/ui/Toast/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/BrowserFeatureProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/FormWizardProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/NextIntlClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/contexts/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/favicon/apple-touch-icon.png");
;
import(/* webpackMode: "eager" */ "/app/src/app/favicon/favicon-16x16.png");
;
import(/* webpackMode: "eager" */ "/app/src/app/favicon/favicon-32x32.png");
;
import(/* webpackMode: "eager" */ "/app/src/app/favicon/favicon.ico");
;
import(/* webpackMode: "eager" */ "/app/src/app/favicon/safari-pinned-tab.svg");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/global-style.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/utils/getLocaleFormatting.tsx");
