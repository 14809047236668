'use client';

import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect } from 'react';

import isBrowser from '@/utils/isBrowser';

const Context = createContext({});

export const useBrowserFeatureContext = () => {
  return useContext(Context);
};

const BrowserFeatureProvider = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    if (isBrowser()) {
      document.documentElement.classList.remove('nojs');
    }
  }, []);

  return <Context.Provider value={{}}>{children}</Context.Provider>;
};

export default BrowserFeatureProvider;
